.form-label {
    display: block;
    margin: 20px 0 5px 0;

    .label-title {
        font-weight: 700;
        font-size: 0.8em;
        text-transform: uppercase;
    }
}

.input-error {
    margin-top: 10px;
    color: #ff8383;
    font-style: italic;
    font-size: 0.7em;
    min-height: 1em;
}

.inputs {
    display: block;
    width: 100%;
    box-sizing: border-box;

    padding: 12px 16px;
    background-color: #edf2f7;
    line-height: 1.25;
    border-radius: .25rem!important;
    border: 1px solid transparent;
    outline: none;
    color: $text;


    &::placeholder {
        color: #a7aec0;
        font-weight: 500;
    }

    &:focus {
        border: 1px #a0aec0 solid;
        background-color: white;
    }
}

.checkbox-form {
    .checkbox-title {
        display: inline-block;
        vertical-align: middle;
    }

    .checkbox-input {display: none;}

    .checkbox {
        display: inline-block;
        background-color: #edf2f7;
        outline: none;

        vertical-align: middle;

        border: 1px #a0aec0 solid;
        border-radius: .25rem;

        height: 20px;
        width: 20px;

        margin-right: 10px;
    }

    input[type="checkbox"]:checked + .checkbox::after {
        content: "✔";
        position: relative;
        top: -6px; left: 3px;
    }

}