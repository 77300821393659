$bg-color: #edf2f7;
$bg-green: #8bc34a;

$box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;
$box-padding: 24px 32px 32px 32px;

$text: #4a5568;
$text-red: #ff8383;

body {
    background-color: #edf2f7;
    margin: 0;
    font-family: 'Cairo', sans-serif;
    color: $text;
    font-size: 18px;
}

.container {
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
}

.text-right {text-align: right;}
.text-center {text-align: center;}

.container-900 {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-page {
    width: 100%;
    height: 100vh;
}

.whitebox {
    background-color: white;
    padding: $box-padding;
    box-shadow: $box-shadow;
}

.divider {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .line {
        height: 2px;
        width: 100%;
        background-color: #b8bfc8;
    }

    .text {
        font-weight: 700;
        padding: 0 15px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

@import "titles";
@import "inputs";
@import "btns";

@import "com-room";
@import "com-welcome";
@import "com-modal";