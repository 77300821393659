.com-modal {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #000000ad;

    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        background-color: white;
        min-height: 100px;
        max-width: 800px;
        width: 100%;
        margin: 20px;
        padding: $box-padding;
        box-sizing: border-box;
        box-shadow: $box-shadow;
        position: relative;

        h1 {
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            line-height: 1.4;
        }

        .points {
            width: fit-content;
            margin: 20px auto 50px auto;

            .point {
                display: inline-block;
                margin: 0 10px;
                width: 65px;
                text-align: center;
                cursor: pointer;
                vertical-align: top;
                transition: 1s;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    transform: scale(1);
                }

                .modify-score {
                    font-size: 15px;
                    font-weight: 700;
                }

                &:hover {
                    img {
                        transform: scale(1.3);
                    }
                }
            }
        }

        .close {
            position: absolute;
            top: 15px;
            right: 25px;
            cursor: pointer;

            &:hover {
                color: rgba($text, 0.8);
            }
        }
    }
}