.btn {
    display: inline-block;
    font-weight: 700;
    color: #2d3748;
    background-color: #e2e8f0;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0 2px;

    i {
        margin-right: 5px;
    }

    &:hover {
        background-color: #cbd5e0;
    }

    &.w-100 {
        width: 100%;
        text-align: center;
        display: block;
    }

    &.green {
        background-color: #88ff81;
        color: #4a5568;
    }

    &.red {
        background-color: $text-red;
    }

    &.btn-sm {
        padding: 4px 8px;
        font-size: 15px;
    }
}