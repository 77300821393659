.com-main-room {
    h1 {
        margin-bottom: 0;
    }

    .room-info {
        margin: 10px 0;

        .item {
            display: inline-block;
            margin-right: 20px;

            .fa-ban {
                color: #dc1818;
            }

            .fa-check {
                color: $bg-green;
            }
        }
    }
}

.com-room {
    min-height: 100px;

    @media screen and (min-width: 880px){
        display: flex;
    }

    .video-frame {
        min-height: 100px;
        width: 100%;

        .player-empty, .player {
            width: 100%;
            min-height: 200px;
            background-color: black;
            box-shadow: $box-shadow;
            margin-bottom: 5px;
            position: relative;

            .buffer-screen {
                width: 100%;
                height: 100%;
                position: absolute; top: 0; left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: black;
                transition: 1s;
                opacity: 0.8;

                i {
                    font-size: 50px;
                    margin-bottom: 30px;
                }
            }
        }

        .full {
            width: 100%;
            height: 100%;
        }

        #player-frame {
            width: 100%;
            height: 100%;
        }

        .player-empty {
           background-color: #b7bbc0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            h1, p {
                margin: 0;
            }
        }

        .song-name {
            line-height: 1.2;
            margin: 20px 0 0 0 !important;
        }

        .song-name-subline {
            margin: 0 0 30px 0;
        }

        .online {
            margin: 80px 0 50px 0;

            .online-title {
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 8px;
            }
        }
    }

    .sub {
        width: 100%;
        margin: 40px 20px 0 20px;


        .title-flex {
            * {display: inline-block; margin-right: 10px}
        }

        .btns {
            margin-top: 10px;
        }

        @media screen and (min-width: 880px){
            width: 600px;
            margin: 0 0 0 20px;
        }
    }

    h2 {
        margin-top: 0;
    }

    .person {
        display: inline-block;
        margin-right: 10px;
        background-color: #67a322;
        color: white;
        border-radius: 3px;
        font-weight: 500;
        padding: 5px 10px;
    }

    .empty-chart {
        display: flex;
        align-items: center;
        opacity: 0.5;

        i {
            font-size: 50px;
            margin-right: 20px;
        }

        div {
            font-size: 25px;
            line-height: 1;
        }
    }

    .song-item {
        position: relative;
        background-color: white;
        padding: 25px 20px 12px 20px;
        margin-bottom: 18px;

        h3 {
            margin: 0;
            line-height: 1.2;
        }

        .possition {
            position: absolute;
            top: -10px;
            left: -8px;
            background-color: $bg-green;
            padding: 0px 20px;
        }

        .score {
            font-size: 14px;
            display: inline-block;
            margin-right: 15px;
        }
    }

    .sync-time {
        font-size: 12px;
        color: grey;
    }
}