h1, h2, h3, h4, h5 {
    font-weight: 700;

    .badge {
        background-color: #b7bbc0;
        font-size: 0.7em;
        padding: 5px 20px;
        color: $text;
        margin-right: 10px;

        &.click {
            cursor: pointer;
        }
        
        &.green {
            background-color: $bg-green;
            color: white;
        }

        &.red {
            background-color: $text-red;
            color: white;
        }
    }
}