.com-welcome {
    margin: 20px;

    .welcome {
        background-color: white;
        box-shadow: $box-shadow;
        margin: 100px auto 10px auto;
        width: 100%;
        max-width: 900px;
        box-sizing: border-box;

        .left {
            padding: $box-padding;
            box-sizing: border-box;
            width: 100%;
        }

        .right {
            background-color: #d0d4d9;
            box-sizing: border-box;
            width: 100%;
            background-size: cover;
        }

        @media screen and (min-width: 660px){
            display: flex;

            .left {
                max-width: 364px;
            }

            .right {

            }
        }
    }
}